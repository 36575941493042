import React from "react";

import "./ShoppingCartExpired.css";
import { useShoppingCart } from "../context/ShoppingCartContext";
import { CartItemRow } from "./CartItemRow";
import { useAppSelector } from "../../../store/hooks";
import { getPageLocaleUrl } from "../utilities/helpers";
import usePageLanguage from "../hooks/usePageLanguage";
import Modal from "react-bootstrap/Modal";
import "../Checkout/Checkout.css";

export default function ShoppingCartExpired() {
  const {
    cartItems,
    clearData,
    setCartExpireDate,
    showCartExpire,
    setShowCartExpire,
  } = useShoppingCart();
  const locale = usePageLanguage();

  const [show, setShow] = React.useState(
    showCartExpire ? showCartExpire : false
  );

  const pages = useAppSelector((state) => state.builder.pages.list);

  const checkoutPage = pages.find(
    (page) => page.page_type === "checkout" && page.language.locale === locale
  );
  var checkoutHref = checkoutPage
    ? getPageLocaleUrl(checkoutPage)
    : "/et/checkout";

  if (checkoutHref.substring(0, 1) !== "/") {
    checkoutHref = "/" + checkoutHref;
  }

  const languageTranslations = {
    et: {
      cartTitle: `SINU OST JÄI POOLELI`,

      cartDescription: `Näib, et jätsite mõned tooted ostukorvi ja ei viinud tellimust lõpuni. Kui soovite selle tellimusega jätkata, siis palun klikkige allpool olevale nupule. Kui te ei soovi eelneva ostuga jätkata, siis võite selle akna sulgeda.`,

      completeOrder: "SOOVIN JÄTKATA",
    },
    en: {
      cartTitle: `YOUR ORDER IS WAITING CONFIRMATION`,

      cartDescription: `It seems that you have left some products to shopping cart and have not completed the order. If you would like to continue the previous order then please click the button below. If you do not wish to continue then you may close this notification.`,

      completeOrder: "I WILL CONTINUE",
    },
  };

  return (
    <div className="shoppingCartExpired" id="shoppingCartExpiredContainer">
      <Modal
        disableRestoreFocus
        show={show}
        onHide={() => {
          setShow(false);
          setShowCartExpire(false);
          clearData();
        }}
        contentClassName="modal-600 "
      >
        {" "}
        <Modal.Header
          closeButton
          style={{
            background: "#f9f9f9",
          }}
        >
          <Modal.Title>
            <span className="fw-bold">
              {languageTranslations[locale].cartTitle}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-2 links-green mb-4">
            {languageTranslations[locale].cartDescription}
          </div>

          <div className="shoppingCartExpiredContentItems">
            {cartItems.map((item) => (
              <CartItemRow key={item.id} {...item} deleteHidden={true} />
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer className="">
          <a
            href={checkoutHref}
            onClick={() => {
              setCartExpireDate();
            }}
            style={{
              color: "#fff",
              fontWeight: "600",
            }}
            className="btn  w-100 shop-controlled-border-radius shop-controlled-color"
          >
            {languageTranslations[locale].completeOrder}
          </a>
        </Modal.Footer>
      </Modal>

      {/* <div className="shoppingCartExpired" id="shoppingCartExpiredContainer">
        <div className="shoppingCartExpiredContent">
          <div
            className="shoppingCartCloseButton"
            onClick={() => {
              const shoppingCartExpiredContainer = document.getElementById(
                "shoppingCartExpiredContainer"
              );

              if (shoppingCartExpiredContainer) {
                shoppingCartExpiredContainer.style.display = "none";
              }

              clearData();
            }}
          >
            <div className="shoppingCartCloseButtonIcon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                  fill="#000"
                />
              </svg>
            </div>
          </div>

          <div className="shoppingCartExpiredContentHeader">
            <span
              className="shoppingCartExpiredContentTitle"
              style={{ textAlign: "left" }}
            >
              {languageTranslations[locale].cartTitle}
            </span>
          </div>

          <div className="shoppingCartExpiredContentContainer">
            <span className="shoppingCartExpiredContentText">
              {languageTranslations[locale].cartDescription}
            </span>

            <a
              href={checkoutHref}
              className="btn btn-primary"
              style={{ width: "fit-content" }}
              onClick={() => {
                const shoppingCartExpiredContainer = document.getElementById(
                  "shoppingCartExpiredContainer"
                );

                if (shoppingCartExpiredContainer) {
                  shoppingCartExpiredContainer.style.display = "none";
                }

                setCartExpireDate();
              }}
            >
              {languageTranslations[locale].completeOrder}
            </a>
          </div>

       

          <div className="shoppingCartExpiredContentItems">
            {cartItems.map((item) => (
              <CartItemRow key={item.id} {...item} />
            ))}
          </div>
        </div>
      </div> */}
    </div>
  );
}
