import { useState } from "react";
import ProductsSortDropdownMenu from "./ProductsSortDropdownMenu";
import FilterListIcon from "@mui/icons-material/FilterList";
import "./ProductsSortComponent.css";
import usePageLanguage from "../hooks/usePageLanguage";
import React from "react";

const languageTranslations = {
  et: {
    title: "Sorteeri",
    price_asc: "Odavamad enne",
  },
  en: {
    title: "Sort by",
    price_asc: "Price asc",
  },
};

export default function ProductsSortComponent({
  sortFilter,
  setSortFilter,
  perPage,
  setPerPage,
}: any) {
  const [open, setOpen] = useState<boolean>(false);

  const locale = usePageLanguage();

  const toggleOpen = () => {
    setOpen(!open);
  };

  var title = "";
  title = languageTranslations[locale].title;

  var titleArray: any = [];
  if (sortFilter) {
    switch (sortFilter) {
      case "price_asc":
        titleArray.push(
          locale === "et"
            ? "Odavamad enne"
            : locale === "en"
            ? "Price asc"
            : locale === "ru"
            ? "Russian Translation"
            : "Default Translation"
        );

        break;
      case "price_desc":
        titleArray.push(
          locale === "et"
            ? "Kallimad enne"
            : locale === "en"
            ? "Price desc"
            : locale === "ru"
            ? "Russian Translation"
            : "Default Translation"
        );
        break;
      case "name_desc":
        titleArray.push(
          locale === "et"
            ? "Nimetuse järgi: Z->A"
            : locale === "en"
            ? "By name: Z->A"
            : locale === "ru"
            ? "Russian Translation"
            : "Default Translation"
        );

        break;
      case "name_asc":
        titleArray.push(
          locale === "et"
            ? "Nimetuse järgi: A->Z"
            : locale === "en"
            ? "By name: A->Z"
            : locale === "ru"
            ? "Russian Translation"
            : "Default Translation"
        );
        break;

      default:
        break;
    }
  }
  if (perPage != 30) {
    titleArray.push(
      locale === "et"
        ? perPage + " toodet lehel"
        : locale === "en"
        ? perPage + " products per page"
        : locale === "ru"
        ? "Russian Translation"
        : "Default Translation"
    );
  }

  if (titleArray.length) {
    title = titleArray.join(", ");
  }

  return (
    <div
      className={
        "ProductsSortComponent dropdown filterDropdown " + (open ? "open" : "")
      }
      style={{
        height: "40px",
      }}
    >
      <button
        onClick={toggleOpen}
        className="btn btn-primary-outline filterDropdownButton shop-controlled-border-radius"
        style={{
          height: "40px",
        }}
      >
        <span id="filterTitle">{title}</span>{" "}
        <FilterListIcon
          style={{
            verticalAlign: "initial",
            fontSize: "14px",
            top: "3px",
            position: "relative",
          }}
        />
      </button>
      {open && (
        <ProductsSortDropdownMenu
          setOpen={setOpen}
          sortFilter={sortFilter}
          setSortFilter={setSortFilter}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      )}
    </div>
  );
}
