import { useEffect } from "react";
import { useAppSelector } from "../../store/hooks";
import { Helmet } from "react-helmet";
import React from "react";

export default function MetadataComponent({ page_seo }) {
  const base_font_size = useAppSelector(
    (state) => state.builder.website.base_font_size ?? "10px"
  );

  useEffect(() => {
    document.body.style.fontSize = base_font_size;
    document.getElementsByTagName("html")[0].style.fontSize = base_font_size;
  });

  return (
    <Helmet>
      <meta charSet="utf-8" />
      {page_seo.seo_title && <title>{page_seo.seo_title}</title>}
      {page_seo.seo_description && (
        <meta name="description" content={page_seo.seo_description} />
      )}
      <meta
        name="robots"
        content={page_seo.seo_no_index ? "noindex, nofollow" : "index, follow"}
      />
      {page_seo.seo_keyword && (
        <meta name="keywords" content={page_seo.seo_keyword} />
      )}

      {/* https://developers.facebook.com/docs/sharing/webmasters/ */}
      {page_seo.seo_og_title && (
        <meta name="og:title" content={page_seo.seo_og_title} />
      )}
      {page_seo.seo_og_description && (
        <meta name="og:description" content={page_seo.seo_og_description} />
      )}
      {page_seo.seo_og_url && (
        <meta name="og:url" content={page_seo.seo_og_url} />
      )}
      {page_seo.seo_og_type && (
        <meta name="og:type" content={page_seo.seo_og_type} />
      )}
      {page_seo.seo_og_image_url && (
        <meta name="og:image" content={page_seo.seo_og_image_url} />
      )}

      {/* https://developer.twitter.com/en/docs/twitter-for-websites/cards/overview/markup */}
      {page_seo.seo_twitter_title && (
        <meta name="twitter:title" content={page_seo.seo_twitter_title} />
      )}
      {page_seo.seo_twitter_description && (
        <meta
          name="twitter:description"
          content={page_seo.seo_twitter_description}
        />
      )}
      {page_seo.seo_twitter_image && (
        <meta name="twitter:image" content={page_seo.seo_twitter_image} />
      )}
      {page_seo.seo_twitter_card && (
        <meta name="twitter:card" content={page_seo.seo_twitter_card} />
      )}
    </Helmet>
  );
}
