import { TextField, Autocomplete, Box } from "@mui/material";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useShoppingCart } from "../context/ShoppingCartContext";

import usePageLanguage from "../../_default/hooks/usePageLanguage";
import { MuiTelInput } from "mui-tel-input";
import { useEffect, useState } from "react";
import { ComponentType } from "react";
import React from "react";
import { countries } from "../utilities/countries";

interface Props {
  show: boolean;
  setShow: (val: boolean) => void;
}

const languageTranslations = {
  et: {
    buyer: "OSTJA",
    first_name: "Eesnimi",
    last_name: "Perenimi",
    phone: "Mobiil",
    email: "E-post",
    save_data: "Andmete salvestamiseks tuleb nõustuda ",
    data_usega: "kasutustingimustega",
    policy: "küpsiste- ja privaatsuspoliitikaga",
    agree_terms: "NÕUSTUN TINGIMUSTEGA JA SALVESTAN",
    organization: "Äriklient",
    client: "Eraklient",
    legal_name: "Juriidiline nimi",
    reg_nr: "Registrikood",
    KMKR_nr: "KMKR nr",
    address: "Aadress",
    no_results: "Tulemusi ei leitud",
    country: "Riik",
    email_required: "E-posti väli on kohustuslik",
    email_invalid: "E-posti aadress on vale",
  },
  en: {
    buyer: "BUYER",
    first_name: "First name",
    last_name: "Last name",
    phone: "Phone",
    email: "Email",
    save_data: "To save your data, you need to agree to our ",
    data_usega: "data use",
    policy: "cookies and privacy policy",
    agree_terms: "AGREE WITH TERMS AND SAVE",
    organization: "Organization",
    client: "Client",
    legal_name: "Legal name",
    reg_nr: "Registration number",
    KMKR_nr: "KMKR number",
    address: "Address",
    no_results: "No results found",
    country: "Country",
    email_required: "E-mail is required",
    email_invalid: "E-mail is invalid",
  },
};
// Define the CountryType interface
interface CountryType {
  code: string;
  label: string;
  phone: string;
}

const BuyerModal: React.FC<{
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ show, setShow }) => {
  let lang = usePageLanguage();
  const { buyer, setBuyer, getOrder } = useShoppingCart();
  const [type, setType] = useState(buyer.type || "buyer");
  const [orgHistoricalNames, setOrgHistoricalNames] = useState(
    buyer.organization_historical_names || ""
  );
  const [buyerSelectedFlag, setBuyerSelectedFlag] = useState(
    `${"https://flagcdn.com/w20/ee.png"}`
  );
  const [organizationSelectedFlag, setOrganizationSelectedFlag] = useState(
    `${"https://flagcdn.com/w20/ee.png"}`
  );

  const [organizationPhone, setOrganizationPhone] = useState(
    buyer.organization_phone ?? "+372"
  );

  useEffect(() => {
    const initialBuyerCountry = countries.find(
      (c) => c.code === buyer.country_code
    );
    const initialOrganizationCountry = countries.find(
      (c) => c.code === buyer.organization_country_code
    );
    if (initialBuyerCountry) {
      setBuyerSelectedFlag(
        `https://flagcdn.com/w20/${initialBuyerCountry.code.toLowerCase()}.png`
      );
    }
    if (initialOrganizationCountry) {
      setOrganizationSelectedFlag(
        `https://flagcdn.com/w20/${initialOrganizationCountry.code.toLowerCase()}.png`
      );
    }
  }, [buyer.country_code, buyer.organization_country_code]);

  const handleBuyerCountryChange = (
    event: any,
    newValue: CountryType | null
  ) => {
    setBuyerSelectedFlag(
      newValue
        ? `https://flagcdn.com/w20/${newValue.code.toLowerCase()}.png`
        : ""
    );
  };

  const handleOrganizationCountryChange = (
    event: any,
    newValue: CountryType | null,
    field: any,
    control: any
  ) => {
    // Check if buyer.organization_phone has a value, (by default it has phone code infront, but check if there are any more numbers after it)

    const orgPhone = control._fields.organization_phone._f.value;
    // const orgPhone = buyer.organization_phone.split(" "); // Get the latest value of the

    if (control._fields.organization_country_code?._f.value !== "EE") {
      setOrganizationOptions([]);
    }

    if (orgPhone) {
      const phoneSplit = orgPhone.split(" ");

      if (phoneSplit.length <= 1 && newValue) {
        setOrganizationPhone(`+${newValue.phone}`);
      }
    }

    setOrganizationSelectedFlag(
      newValue
        ? `https://flagcdn.com/w20/${newValue.code.toLowerCase()}.png`
        : ""
    );
  };

  const [orgStatus, setOrgStatus] = useState(
    buyer.organization_ariregister_status || ""
  );
  const [orgUrl, setOrgUrl] = useState(
    buyer.organization_ariregister_url || ""
  );
  const [organizationOptions, setOrganizationOptions] = useState<any>([]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      type: type,
      firstname: buyer.firstname ?? "",
      surname: buyer.surname ?? "",
      phone: buyer.phone ?? "+372",
      email: buyer.email ?? "",
      language: lang ?? "",
      country_code: buyer.country_code ?? "EE",
      organization_country_code: buyer.organization_country_code ?? "EE",
      organization_name: buyer.organization_name ?? "",
      organization_reg_nr: buyer.organization_reg_nr ?? "",
      organization_KMKR_number: buyer.organization_KMKR_number ?? "",
      organization_phone: organizationPhone ?? "+372",
      organization_email: buyer.organization_email ?? "",
      organization_address: buyer.organization_address ?? "",
      organization_historical_names: buyer.organization_historical_names ?? "",
      organization_ariregister_status:
        buyer.organization_ariregister_status ?? "",
      organization_ariregister_url: buyer.organization_ariregister_url ?? "",
    },
  });

  const onSubmit = (data: any) => {
    console.log(data, "THIS IS THE DATA, AMAZING");

    const buyerData = {
      ...buyer,
      ...data,
      type: type,
      organization_historical_names: orgHistoricalNames
        ? orgHistoricalNames
        : "",
      organization_ariregister_status: orgStatus ? orgStatus : "",
      organization_ariregister_url: orgUrl ? orgUrl : "",
      organization_phone: organizationPhone ?? "+372",
    };
    setBuyer(buyerData);
    setShow(false);
    getOrder(buyerData);
  };

  const handleOrganizationQuery = (query: string) => {
    const url = `https://ariregister.rik.ee/est/api/autocomplete?q=${query}&results=10`;

    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        if (!res.data) {
          return;
        }
        const data = [...res.data].map((item: any) => {
          return {
            label: item.name,
            organization_name: item.name,
            organization_reg_nr: item.reg_code,
            organization_KMKR_number: "",
            organization_phone: "+372",
            organization_email: "",
            organization_address: `${item.legal_address}, ${item.zip_code}`,
            organization_historical_names:
              item.historical_names.length > 0
                ? `${item.historical_names
                    .map((historicalName: any) => historicalName.name)
                    .join(", ")}`
                : "",
            organization_ariregister_status: item.status ? item.status : "",
            organization_ariregister_url: item.url ? item.url : "",
          };
        });

        setOrganizationOptions(data);
      });
  };

  // Define the type for your form's field names
  type FieldName =
    | "type"
    | "firstname"
    | "surname"
    | "phone"
    | "email"
    | "organization_name"
    | "organization_reg_nr"
    | "organization_KMKR_number"
    | "organization_phone"
    | "organization_email"
    | "organization_address";

  const setFieldValue = (fieldName: FieldName, value: any) => {
    setValue(fieldName, value);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        contentClassName="modal-600 buyerModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="badge badge-square badge-primary me-3">2</span>
            <span className="fw-bold">{languageTranslations[lang].buyer}</span>
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                marginTop: "0px",
                marginBottom: "20px",
              }}
            >
              <div
                onClick={() => {
                  setType("buyer");
                }}
                className={`
            buyerModal__btn ${
              type == "buyer" ? "buyerModal__btn--active" : ""
            }`}
              >
                {languageTranslations[lang].client}
              </div>

              <div
                onClick={() => {
                  setType("organization");
                }}
                className={`buyerModal__btn ${
                  type == "organization" ? "buyerModal__btn--active" : ""
                }`}
              >
                {languageTranslations[lang].organization}
              </div>
            </div>

            {type === "organization" && (
              <div className="row">
                <div className="col-md-4">
                  <Controller
                    name="organization_country_code"
                    rules={{ required: true }}
                    control={control}
                    defaultValue={buyer.organization_country_code || "EE"} // Set default value here
                    render={({ field }) => (
                      <Autocomplete
                        options={countries}
                        className="text-field-color"
                        value={
                          countries.find((c) => c.code === field.value) || null
                        }
                        onChange={(event, newValue: any) => {
                          field.onChange(newValue ? newValue.code : ""); // Handle onChange to update field value
                          handleOrganizationCountryChange(
                            event,
                            newValue,
                            field,
                            control
                          ); // Handle country change
                        }}
                        freeSolo
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            {option.label}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={languageTranslations[lang].country}
                            variant="standard"
                            error={
                              errors.organization_country_code?.type ===
                              "required"
                            }
                            InputProps={{
                              ...params.InputProps,
                              autoComplete: "new-password",
                              startAdornment: organizationSelectedFlag && (
                                <img
                                  loading="lazy"
                                  width="26"
                                  src={organizationSelectedFlag}
                                  srcSet={`${organizationSelectedFlag.replace(
                                    "w20",
                                    "w40"
                                  )} 2x`}
                                  alt=""
                                  style={{ marginRight: 8 }}
                                />
                              ),
                              // Hide the country name in the input field
                              readOnly: true,
                              value: "",
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </div>

                <div className="col-md-8">
                  <Controller
                    name="organization_name"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      // <TextField
                      //   className="text-field-color"
                      //   InputLabelProps={{
                      //     style: { color: "#333", fontSize: "14px" },
                      //   }}
                      //   fullWidth
                      //   // Make tab automatically active
                      //   autoFocus={true}
                      //   label={languageTranslations[lang].legal_name}
                      //   variant="standard"
                      //   value={field.value}
                      //   onChange={field.onChange}
                      //   inputRef={field.ref}
                      //   error={errors.organization_name?.type == "required"}
                      // />

                      <Autocomplete
                        className="text-field-color"
                        options={organizationOptions}
                        value={field.value}
                        freeSolo
                        // noOptionsText={languageTranslations[lang].no_results}
                        // run if selected
                        onInputChange={(event, newInputValue) => {
                          console.log("test");

                          //field.onChange(newInputValue);

                          // Get the selected value from the options

                          // setFieldValue("organization_name", newInputValue);
                          const selectedValue = organizationOptions.find(
                            (option) =>
                              option.organization_name == newInputValue
                          );

                          if (!selectedValue) {
                            setFieldValue("organization_name", newInputValue);
                          }

                          // Update organization_name, organization_reg_nr, organization_KMKR_number, organization_phone, organization_email, organization_address

                          if (selectedValue) {
                            setFieldValue("organization_name", newInputValue);
                            setFieldValue(
                              "organization_reg_nr",
                              selectedValue.organization_reg_nr
                            );
                            setFieldValue(
                              "organization_KMKR_number",
                              selectedValue.organization_KMKR_number
                            );
                            setFieldValue(
                              "organization_phone",
                              selectedValue.organization_phone
                            );
                            setFieldValue(
                              "organization_email",
                              selectedValue.organization_email
                            );
                            setFieldValue(
                              "organization_address",
                              selectedValue.organization_address
                            );

                            setOrgHistoricalNames(
                              selectedValue.organization_historical_names
                            );

                            setOrgStatus(
                              selectedValue.organization_ariregister_status
                            );

                            setOrgUrl(
                              selectedValue.organization_ariregister_url
                            );
                          }

                          console.log(event, newInputValue, "values changed");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            autoFocus={true}
                            InputLabelProps={{
                              style: { color: "#333", fontSize: "14px" },
                            }}
                            onChange={(e) => {
                              if (
                                control._fields.organization_country_code?._f
                                  .value === "EE"
                              ) {
                                handleOrganizationQuery(e.target.value);
                              } else {
                                setOrganizationOptions([]);
                              }
                            }}
                            label={languageTranslations[lang].legal_name}
                            error={errors.organization_name?.type == "required"}
                          />
                        )}
                      />
                    )}
                  />
                </div>

                <div className="col-md-12">
                  <Controller
                    name="organization_address"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        className="text-field-color"
                        InputLabelProps={{
                          style: { color: "#333", fontSize: "14px" },
                        }}
                        fullWidth
                        label={languageTranslations[lang].address}
                        variant="standard"
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                        error={errors.organization_address?.type == "required"}
                      />
                    )}
                  />
                </div>

                <div className="col-md-3">
                  <Controller
                    name="organization_reg_nr"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        className="text-field-color"
                        InputLabelProps={{
                          style: { color: "#333", fontSize: "14px" },
                        }}
                        fullWidth
                        label={languageTranslations[lang].reg_nr}
                        variant="standard"
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                        error={errors.organization_reg_nr?.type == "required"}
                      />
                    )}
                  />
                </div>

                <div className="col-md-4">
                  <Controller
                    name="organization_KMKR_number"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        className="text-field-color"
                        InputLabelProps={{
                          style: { color: "#333", fontSize: "14px" },
                        }}
                        fullWidth
                        label={languageTranslations[lang].KMKR_nr}
                        variant="standard"
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                        error={
                          errors.organization_KMKR_number?.type == "required"
                        }
                      />
                    )}
                  />
                </div>

                <div className="col-md-5">
                  <Controller
                    name="organization_phone"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <MuiTelInput
                        className=""
                        InputLabelProps={{
                          style: { color: "#333", fontSize: "14px" },
                        }}
                        fullWidth
                        label={languageTranslations[lang].phone}
                        variant="standard"
                        required
                        value={organizationPhone}
                        onChange={(value) => {
                          console.log(value, "THIS VALUE");

                          setOrganizationPhone(value);
                          field.onChange(value);
                        }}
                        inputRef={field.ref}
                        error={errors.organization_phone?.type == "required"}
                      />
                    )}
                  />
                </div>

                <div className="col-md-12">
                  <Controller
                    name="organization_email"
                    rules={{
                      required: languageTranslations[lang].email_required,
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: languageTranslations[lang].email_invalid,
                      },
                    }}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        className="text-field-color"
                        InputLabelProps={{
                          style: { color: "#333", fontSize: "14px" },
                        }}
                        fullWidth
                        sx={
                          // Add margin 0 to muiInput-root
                          {
                            "& .MuiInputBase-root": {
                              marginBottom: 0,
                            },
                          }
                        }
                        FormHelperTextProps={{
                          sx: {
                            fontSize: "14px",
                          },
                        }}
                        label={languageTranslations[lang].email}
                        variant="standard"
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                        error={!!errors.organization_email}
                        helperText={
                          errors.organization_email
                            ? errors.organization_email.message
                            : ""
                        }
                      />
                    )}
                  />
                </div>
              </div>
            )}

            {type === "buyer" && (
              <div className="row">
                <div className="col-md-6">
                  <Controller
                    name="firstname"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        className="text-field-color"
                        InputLabelProps={{
                          style: { color: "#333", fontSize: "14px" },
                        }}
                        fullWidth
                        // Make tab automatically active
                        autoFocus={true}
                        label={languageTranslations[lang].first_name}
                        variant="standard"
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                        error={errors.firstname?.type == "required"}
                      />
                    )}
                  />
                </div>
                <div className="col-md-6">
                  <Controller
                    name="surname"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        InputLabelProps={{
                          style: { color: "#333", fontSize: "14px" },
                        }}
                        fullWidth
                        label={languageTranslations[lang].last_name}
                        variant="standard"
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                        error={errors.surname?.type == "required"}
                      />
                    )}
                  />
                </div>
                <div className="col-md-6">
                  <Controller
                    name="email"
                    rules={{
                      required: languageTranslations[lang].email_required,
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: languageTranslations[lang].email_invalid,
                      },
                    }}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        InputLabelProps={{
                          style: { color: "#333", fontSize: "14px" },
                        }}
                        fullWidth
                        sx={
                          // Add margin 0 to muiInput-root
                          {
                            "& .MuiInputBase-root": {
                              marginBottom: 0,
                            },
                          }
                        }
                        FormHelperTextProps={{
                          sx: {
                            fontSize: "14px",
                          },
                        }}
                        label={languageTranslations[lang].email}
                        variant="standard"
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                        error={!!errors.email}
                        helperText={errors.email ? errors.email.message : ""}
                      />
                    )}
                  />
                </div>
                <div className="col-md-6">
                  <Controller
                    name="phone"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <MuiTelInput
                        className=""
                        InputLabelProps={{
                          style: { color: "#333", fontSize: "14px" },
                        }}
                        fullWidth
                        label={languageTranslations[lang].phone}
                        variant="standard"
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                        error={errors.phone?.type == "required"}
                      />
                    )}
                  />
                </div>

                <div className="col-md-6">
                  <Controller
                    name="country_code"
                    rules={{ required: true }}
                    control={control}
                    defaultValue={buyer.country_code || "EE"}
                    render={({ field }) => (
                      <Autocomplete
                        options={countries}
                        value={
                          countries.find((c) => c.code === field.value) || null
                        }
                        onChange={(event, newValue) => {
                          field.onChange(newValue ? newValue.code : ""); // Handle onChange to update field value
                          handleBuyerCountryChange(event, newValue); // Handle country change
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            {option.label}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <>
                            <TextField
                              {...params}
                              InputLabelProps={{
                                style: { color: "#333", fontSize: "14px" },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                autoComplete: "new-password",
                                startAdornment: buyerSelectedFlag && (
                                  <img
                                    loading="lazy"
                                    width="26"
                                    src={buyerSelectedFlag}
                                    srcSet={`${buyerSelectedFlag.replace(
                                      "w20",
                                      "w40"
                                    )} 2x`}
                                    alt=""
                                    style={{ marginRight: 8 }}
                                  />
                                ),
                              }}
                              sx={{
                                "& .MuiInputBase-root": {
                                  marginBottom: 0,
                                },
                              }}
                              FormHelperTextProps={{
                                sx: {
                                  fontSize: "14px",
                                },
                              }}
                              fullWidth
                              label={languageTranslations[lang].country}
                              variant="standard"
                              error={errors.country_code?.type == "required"}
                            />
                          </>
                        )}
                      />
                    )}
                  />
                </div>
              </div>
            )}

            <div className="mt-2 links-green">
              {languageTranslations[lang].save_data}
              <a href="/terms" target="_blank" className="color-primary">
                {languageTranslations[lang].data_usega}
              </a>
              ,{" "}
              <a href="/privacy" target="_blank" className="color-primary">
                {languageTranslations[lang].policy}
              </a>
              .
            </div>
          </Modal.Body>
          <Modal.Footer className="">
            <button
              type="submit"
              style={{
                color: "#fff",
                fontWeight: "600",
              }}
              className="btn w-100 shop-controlled-border-radius shop-controlled-color"
            >
              {languageTranslations[lang].agree_terms}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default BuyerModal;
