import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useShoppingCart } from "../context/ShoppingCartContext";
import RenderChildren from "../RenderChildren";
import { useAppSelector } from "../../../store/hooks";
import usePageLanguage from "../hooks/usePageLanguage";
import React from "react";

interface Props {
  show: boolean;
  setShow: (val: boolean) => void;
}

const languageTranslations = {
  et: {
    agree_terms: "TINGIMUSTEGA TUTVUMINE",
    save_data: "Andmete salvestamiseks tuleb nõustuda ",
    data_usage: "kasutustingimustega",
    policy: "küpsiste- ja privaatsuspoliitikaga",
    accept_terms: "NÕUSTUN TINGIMUSTEGA JA SALVESTAN",
  },
  en: {
    agree_terms: "AGREEING TO TERMS",
    save_data: "To save your data, you have to agree with",
    data_usage: "usage of data",
    policy: "cookies and privacy policy",
    accept_terms: "ACCEPT TERMS AND SAVE",
  },
};

export default function CheckoutConfirmModal({ show, setShow }: Props) {
  const locale = usePageLanguage();
  const { checkoutConfirm } = useShoppingCart();
  const templates = useAppSelector((state) => state.builder.templates);
  const template = templates.find((x: any) => x.use === "checkoutTerms");
  let termschildren: any = [];

  if (template) {
    termschildren = [
      {
        id: "terms",
        child_id: template.webpage_component_id,
      },
    ];
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  const onSubmit = () => {
    checkoutConfirm();
    setShow(false);
  };

  const onError = (data: any) => {
    console.log(data);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      contentClassName="modal-600 checkoutConfirmModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="badge badge-square badge-primary me-3">5</span>
          <span className="fw-bold">
            {" "}
            {languageTranslations[locale].agree_terms}
          </span>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Modal.Body>
          <div className="terms-section">
            {/* <TermsContent /> */}
            <RenderChildren
              key={"termschildren"}
              childWebpageComponentRelations={termschildren}
            />
          </div>
          <div className="mt-2 links-green">
            {languageTranslations[locale].save_data}{" "}
            <a href="/terms" target="_blank" className="color-primary">
              {languageTranslations[locale].data_usage}
            </a>
            ,{" "}
            <a href="/privacy" target="_blank" className="color-primary">
              {languageTranslations[locale].policy}
            </a>
            .
          </div>
        </Modal.Body>
        <Modal.Footer className="">
          <button
            type="submit"
            style={{
              color: "#fff",
            }}
            className="btn  w-100 shop-controlled-color shop-controlled-border-radius"
          >
            {languageTranslations[locale].accept_terms}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
