import {
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  Autocomplete,
} from "@mui/material";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
  DeliveryMethod,
  useShoppingCart,
} from "../context/ShoppingCartContext";
import { pf } from "../utilities/helpers";
import Delivery, { Machine } from "./Delivery";
import usePageLanguage from "../hooks/usePageLanguage";
import { MuiTelInput } from "mui-tel-input";

interface Props {
  show: boolean;
  setShow: (val: boolean) => void;
  deliveryMethod: DeliveryMethod | null;
}

const languageTranslations = {
  et: {
    delivery_details: "TARNE ANDMED",
    choose_parcel_machine: "Vali pakiautomaat",
    buyer_is_reciever: "Ostja võtab kauba vastu",
    first_name: "Eesnimi",
    last_name: "Perenimi",
    phone: "Mobiil",
    email: "E-post",
    save_data: "Andmete salvestamiseks tuleb nõustuda ",
    data_usega: "kasutustingimustega",
    policy: "küpsiste- ja privaatsuspoliitikaga",
    agree_terms: "NÕUSTUN TINGIMUSTEGA JA SALVESTAN",
    address: "Aadress",
    postal_code: "Postiindeks",
    city: "Linn",
  },
  en: {
    delivery_details: "DELIVERY DETAILS",
    choose_parcel_machine: "Choose parcel machine",
    buyer_is_reciever: "Buyer accepts the delivery",
    first_name: "First name",
    last_name: "Last name",
    phone: "Phone",
    email: "Email",
    save_data: "To save your data, you need to agree to our ",
    data_usega: "data use",
    policy: "cookies and privacy policy",
    agree_terms: "AGREE WITH TERMS AND SAVE",
    aadress: "Address",
    postal_code: "Postal code",
    city: "City",
  },
};

export default function CheckoutDeliveryModal({
  show,
  setShow,
  deliveryMethod,
}: Props) {
  const { orderDeliveryMethod, confirmDeliveryData, buyer } = useShoppingCart();

  let lang = usePageLanguage();

  var receiverEmail = "";
  var receiverFirstname = "";
  var receiverSurname = "";
  var receiverPhone = "";
  var address = "";
  var city = "";
  var postal_code = "";
  var buyerIsReciever = true;

  if (orderDeliveryMethod && orderDeliveryMethod.id) {
    receiverEmail = buyer.email;
    receiverFirstname = buyer.firstname;
    receiverSurname = buyer.surname;
    receiverPhone = buyer.phone;

    if (orderDeliveryMethod.receiverEmail) {
      receiverEmail = orderDeliveryMethod.receiverEmail;
      receiverFirstname = orderDeliveryMethod.receiverFirstname;
      receiverSurname = orderDeliveryMethod.receiverSurname;
      receiverPhone = orderDeliveryMethod.receiverPhone;
    }
    address = orderDeliveryMethod.address;
    city = orderDeliveryMethod.city;
    postal_code = orderDeliveryMethod.postal_code;
    buyerIsReciever = orderDeliveryMethod.buyer_is_receiver ? true : false;
  }

  const [parcelMachineLocation, setParcelMachineLocation] =
    useState<Machine | null>(null);

  var defaultValues = {
    receiverEmail: receiverEmail,
    receiverFirstname: receiverFirstname,
    receiverSurname: receiverSurname,
    receiverPhone: receiverPhone ? receiverPhone : "+372",
    address: address,
    city: city,
    postal_code: postal_code,
    buyerIsReciever: buyerIsReciever,
    deliveryMethod: deliveryMethod?.id,
    parcelMachineLocation: parcelMachineLocation,
  };

  const {
    control,
    handleSubmit,
    watch,
    register,
    unregister,
    formState: { errors },
  } = useForm({
    mode: "all",
    values: defaultValues,
  });

  const watchbuyerIsReciever = watch("buyerIsReciever");

  const onSubmit = (data: any) => {
    if (parcelMachineLocation) {
      confirmDeliveryData({
        ...data,
        address: parcelMachineLocation.address,
        city: parcelMachineLocation.city,
        postal_code: parcelMachineLocation.zipCode,
        parcelDescription: parcelMachineLocation.description,
      });
    } else {
      confirmDeliveryData({
        ...data,
      });
    }
    setShow(false);
  };

  const onError = (data: any) => {
    console.log(data);
  };

  var delivery: any = null;
  const [deliveryParcels, setDeliveryParcels] = useState([]);
  useEffect(() => {
    if (deliveryMethod?.getDeliveryLocationsUrl) {
      delivery = Delivery(
        deliveryMethod.getDeliveryLocationsUrl,
        deliveryMethod.type
      );
      // Get list of parcel machines
      delivery.getParcelMachines(function (res: any) {
        setDeliveryParcels(res);
      });
    }
  }, [deliveryMethod]);

  useEffect(() => {
    setParcelMachineLocation(
      deliveryParcels.find(
        (x: any) => x.machineLocation === orderDeliveryMethod?.parcelMachineName
      ) ?? null
    );
  }, [deliveryParcels, orderDeliveryMethod?.parcelMachineName]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      id="checkoutDeliveryMethodModal"
      contentClassName="modal-600"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="badge badge-square badge-primary me-3">3</span>
          <span className="fw-bold">
            {languageTranslations[lang].delivery_details}
          </span>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Modal.Body>
          <div className="row">
            <div className="col-xs-12 selectedDeliveryMethodButtonContainer">
              <button
                className={
                  "btn btn-primary-outline text-left checkoutDeliveryButton" /*+ (deliveryMethod?.pickup ? ' margin-bottom-30' : '')*/
                }
                style={{ width: "100%" }}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {deliveryMethod?.locale_translations[lang]?.name}
                <span className="pull-right">
                  {" "}
                  {pf(deliveryMethod!.deliveryMethodPrice.price)} &euro;
                </span>
              </button>
            </div>
            {deliveryMethod?.isParcelMachine && (
              <div
                id="form-delivery-parcelMachine"
                className="col-md-12 padding-top-15"
              >
                <FormControl fullWidth>
                  <Controller
                    name="parcelMachineLocation"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        onChange={(e, parcelMachineLocation) => {
                          setParcelMachineLocation(parcelMachineLocation);
                          field.onChange(e, parcelMachineLocation);
                        }}
                        options={deliveryParcels}
                        value={parcelMachineLocation}
                        getOptionLabel={(parcel: any) => parcel.machineLocation}
                        isOptionEqualToValue={(option, value) =>
                          option.machineLocation == value.machineLocation
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder={
                              languageTranslations[lang].choose_parcel_machine
                            }
                          />
                        )}
                      />
                    )}
                  />
                </FormControl>
              </div>
            )}
            {!(deliveryMethod as any).pickup &&
              !(deliveryMethod as any).isParcelMachine && (
                <div id="form-delivery-address" className="padding-top-15">
                  <div className="col-md-12">
                    <Controller
                      name="address"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          autoFocus
                          label={languageTranslations[lang].address}
                          variant="standard"
                          value={field.value}
                          onChange={field.onChange}
                          inputRef={field.ref}
                          error={errors.address?.type == "required"}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-6">
                    <Controller
                      name="city"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label={languageTranslations[lang].city}
                          variant="standard"
                          value={field.value}
                          onChange={field.onChange}
                          inputRef={field.ref}
                          error={errors.city?.type == "required"}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-6">
                    <Controller
                      name="postal_code"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label={languageTranslations[lang].postal_code}
                          variant="standard"
                          value={field.value}
                          onChange={field.onChange}
                          inputRef={field.ref}
                          error={errors.postal_code?.type == "required"}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Controller
                name="buyerIsReciever"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: "var(--shop-theme-color)" }}
                        classes={{ root: "custom-checkbox-root" }}
                        value={field.value}
                        checked={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                      />
                    }
                    label={languageTranslations[lang].buyer_is_reciever}
                  />
                )}
              />
            </div>
          </div>
          {!watchbuyerIsReciever && (
            <div className="row" id="buyerIsNotRecieverContainer">
              <div className="col-md-6">
                <Controller
                  name="receiverFirstname"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      style={{
                        color: "var(--shop-theme-color)",
                        fontSize: "14px",
                      }}
                      fullWidth
                      label={languageTranslations[lang].first_name}
                      variant="standard"
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref}
                      error={errors.receiverFirstname?.type == "required"}
                    />
                  )}
                />
              </div>
              <div className="col-md-6">
                <Controller
                  name="receiverSurname"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      style={{ fontSize: "14px" }}
                      fullWidth
                      label={languageTranslations[lang].last_name}
                      variant="standard"
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref}
                      error={errors.receiverSurname?.type == "required"}
                    />
                  )}
                />
              </div>
              <div className="col-md-6">
                <Controller
                  name="receiverEmail"
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Invalid email address",
                    },
                  }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      style={{ fontSize: "14px" }}
                      fullWidth
                      sx={
                        // Add margin 0 to muiInput-root
                        {
                          "& .MuiInputBase-root": {
                            marginBottom: 0,
                          },
                        }
                      }
                      FormHelperTextProps={{
                        sx: {
                          fontSize: "14px",
                        },
                      }}
                      label={languageTranslations[lang].email}
                      variant="standard"
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref}
                      error={errors.receiverEmail?.type == "required"}
                      helperText={
                        errors.receiverEmail ? errors.receiverEmail.message : ""
                      }
                    />
                  )}
                />
              </div>
              <div className="col-md-6">
                <Controller
                  name="receiverPhone"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <MuiTelInput
                      className=""
                      InputLabelProps={{
                        style: { color: "#333", fontSize: "14px" },
                      }}
                      fullWidth
                      label={languageTranslations[lang].phone}
                      variant="standard"
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref}
                      error={errors.receiverPhone?.type == "required"}
                    />

                    // <TextField
                    //   fullWidth
                    //   style={{ fontSize: "14px" }}
                    //   label={languageTranslations[lang].phone}
                    //   variant="standard"
                    //   value={field.value}
                    //   onChange={field.onChange}
                    //   inputRef={field.ref}
                    //   error={errors.receiverPhone?.type == "required"}
                    // />
                  )}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-xs-12 description">
              {deliveryMethod?.locale_translations[lang]?.description}
            </div>
          </div>

          <div className="mt-2 links-green">
            {languageTranslations[lang].save_data}
            <a href="/terms" target="_blank" className="color-primary">
              {languageTranslations[lang].data_usega}
            </a>
            ,{" "}
            <a href="/privacy" target="_blank" className="color-primary">
              {languageTranslations[lang].policy}
            </a>
          </div>
        </Modal.Body>
        <Modal.Footer className="">
          <button
            type="submit"
            style={{
              color: "#fff",
              fontWeight: "600",
            }}
            className="btn  w-100 shop-controlled-border-radius shop-controlled-color"
          >
            {languageTranslations[lang].agree_terms}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
